* {
  font-family: 'Baloo Bhaina 2', cursive;
}

.img-note {
  padding: 0.3em !important;
}

.label-image {
  margin-bottom: -15px !important;
}

.MuiTypography-colorTextSecondary {
  color: black !important;
}

p {
  margin-bottom: 0 !important;
}

.badge-status {
  background-color: green;
  color: white;
  padding: 1px 50px;
  border-radius: 5px;
}

.note {
  line-height: 23px !important;
}

.img-thumb {
  width: 40px !important;
  height: 40px !important;
  object-fit: scale-down !important;
  cursor: pointer;
}

.outlet {
  padding: 15px 18px !important;
  border-radius: 20px;
}

.MuiDialogTitle-root {
  padding-bottom: 0px !important;
}

.img-history {
  width: 80px !important;
}
.img-dummy {
  width: 40px !important;
}
.img-sum-dummy {
  width: 200px !important;
}

.history {
  font-size: 12px !important;
}

.scrolled {
  /* overflow-x: scroll; */
  overflow: auto;
  background-color: white;
  border-radius: 20px !important;
  white-space: nowrap;
}

.item {
  display: inline-block;
  text-align: center;
  padding: 6px;
  max-width: 120px !important;
}

.text-validate {
  margin: 25px 0;
}

.btn-primary {
  border-color: #031d44 !important;
}

/* .card-summary{
  border: 2px solid red !important;
  border-radius: 5px !important;
} */

.border-image {
  border: 1px solid black !important;
  border-radius: 10px !important;
  /* height: 500px !important; */
}
.border-validate {
  border: 1px solid red !important;
  border-radius: 10px !important;
  min-height: 435px !important;
}

.MuiPaper-rounded {
  border-radius: 20px !important;
}

.text-validate {
  font-weight: bold !important;
  color: black !important;
}
hr {
  border: 1px solid red !important;
}

.wrap-validation {
  margin-bottom: 20px !important;
}

.wrap {
  height: 400px !important;
  background-color: red !important;
}
@media screen and (min-width: 1200px) {
  .btn-submit {
    margin-top: 30px !important;
  }
  .img-outlet {
    width: 400px !important;
    height: 400px !important;
    align-self: center !important;
    object-fit: scale-down !important;
  }

  .border-validate {
    border: 1px solid red !important;
    border-radius: 10px !important;
    min-height: 435px !important;
  }
}
@media screen and (max-width: 1199px) {
  .btn-submit {
    margin-top: 15px !important;
  }
  .img-outlet {
    width: 300px !important;
    height: 300px !important;
    align-self: center !important;
    object-fit: scale-down !important;
  }
  .border-validate {
    border: 1px solid red !important;
    border-radius: 10px !important;
    min-height: 335px !important;
  }
  .wrap-validation {
    margin-bottom: -15px !important;
  }
}
@media screen and (max-width: 678px) {
  .img-outlet {
    width: 200px !important;
    height: 200px !important;
    align-self: center !important;
    object-fit: scale-down !important;
  }
  .wrap-validation {
    margin-bottom: -30px !important;
  }
}
@media screen and (max-width: 768px) {
  .img-outlet {
    width: 300px !important;
    height: 300px !important;
    align-self: center !important;
    object-fit: scale-down !important;
  }
  .wrap-validation {
    margin-bottom: -20px !important;
  }
}
